<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="purchases" v-model:filters="filters1" v-model:expandedRows="expandedRows"
                    v-model:selection="selectedRow" selectionMode="single" :metaKeySelection="false" :filters="filters1"
                    filterDisplay="menu" dataKey="id" :globalFilterFields="['purchaseno','factoryname']" scrollable
                    :tableStyle="styleHeight" :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading"
                    :paginator="true" :lazy="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
                    :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:40%">
                                <div class="grid">
                                    <div class="col-4">
                                        <InputText v-model="filters1['global'].value" placeholder="输入采购合同号或工厂简称模糊查询"
                                            style="width:100%" />
                                    </div>
                                    <div class="col-5">
                                        <InputText v-model="skuval" placeholder="输入产品SKU/MPN模糊查询" style="width:100%" />
                                    </div>
                                    <div class="col-3">
                                        <Button type="button" icon="pi pi-search" label="查询" @click="customFilter();"
                                            class="p-button-outlined mb-2" />
                                    </div>
                                </div>
                            </span>
                        </div>
                    </template>
                    <!-- <Column frozen bodyStyle="width:45px;" headerStyle="width:45px;">
                        <template #body="slotProps">
                            <button @click="expandertoggle($event,slotProps.data)" aria-haspopup="true"
                                :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span
                                    class="p-row-toggler-icon pi pi-chevron-right"></span><span
                                    class="p-ink"></span></button>
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" :id="slotProps.data['id']"
                                style="width:650px" :breakpoints="{'960px': '75vw'}">
                                <TabView>
                                    <TabPanel header="采购产品">
                                        <DataTable :value="expandedata['products']"
                                            v-model:selection="selectedProductRow" dataKey="id"
                                            :metaKeySelection="false" scrollHeight="500px" selectionMode="multiple"
                                            scrollDirection="both" responsiveLayout="scroll">
                                            <Column selectionMode="multiple" frozen headerStyle="width: 3em"></Column>
                                            <Column field="mpn" style="min-width:200px" frozen header="平台MPN"></Column>
                                            <Column field="sku" style="min-width:200px" frozen header="工厂SKU"></Column>
                                            <Column field="productname" style="min-width:200px" frozen header="产品名称">
                                                <template #body="slotProps">
                                                    <label class="oneline"
                                                        v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                                                </template>
                                            </Column>
                                            <Column style="min-width:100px" header="图片">
                                                <template #body="slotProps">
                                                    <Image :src="getFilePatch(slotProps.data.url)" width="50" preview />
                                                </template>
                                            </Column>
                                            <Column field="quantity" style="min-width:200px" header="采购数量"></Column>
                                            <Column field="colorname" style="min-width:80px" header="颜色"></Column>
                                            <Column field="brand" style="min-width:200px" header="品牌"></Column>
                                            <Column field="size" style="min-width:200px" header="规格尺寸"></Column>
                                            <Column field="inspection" style="min-width:300px" header="是否验货">
                                                <template #body="slotProps">
                                                    {{formatData(slotProps.data['inspection'])}}
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </TabPanel>
                                </TabView>
                            </OverlayPanel>
                        </template>
                    </Column> -->
                    <Column selectionMode="single" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                    </Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.purchaseno" @click="openPurchase(slotProps.data,false)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :childExpandePageConfig="childExpandeConfig"
                :saveBeforeMethod="saveBefore" :CellEditCompleteMethod="cellEditComplete"
                :childNewDataConfig="childNewDataConfig" :childDelDataConfig="childDelDataConfig"
                :initDetailMethod="initDetail" :loadDataAfterMethod="loadDataAfter" :childTableFooter="childTableFooter"
                :mainelementCss="mainelementCss" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :pageJsonConfig="pageJsonConfig" :freshListMethod="freshList" :closeParentMehod="closeMaximizable"
                :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button :label="editHistoryButTxt" icon="pi pi-tags" class="ml-auto" @click="showEditHistory" />
                <Button label="关闭" icon="pi pi-power-off" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="数据导出" v-model:visible="importWordDisplay" :style="{width: '30vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="importFinish" class="field col-12 md:col-12">
                <Button label="数据处理完成,请点击下载" @click="getWordFilePatch" class="p-button-link" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <ProgressBar mode="indeterminate" style="height: .5em" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <label>数据处理中....</label>
            </div>
        </div>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 200px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    // import Image from 'primevue/image';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import purchasePageJson from "@/data/purchaseConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '695';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                skuval: '',
                editHistoryButTxt: '显示修改痕迹',
                currentPage: 1,
                newId: 2,
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                expandedata: {
                    "products": []
                },
                butLoading: false,
                recordsubject: '',
                readOnly: true,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                position: 'bottomleft',
                currentState: '流程信息',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: 'purchase',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Purchase',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                pageJsonConfig: {},
                pageJsonConfig1: {},
                pageJsonConfig2: {},
                currencyDict: [],
                flowhistoryJson: null,
                uploadurl: '',
                flowId2: '',
                startStateId2: '',
                expandedRows: ref(),
                selectedRow: ref(),
                selectedProductRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '采购列表',
                selectedKey: {
                    695: true
                },
                expandedKey: null,
                childExpandeConfig: {},
                childExpandeConfig1: {},
                childExpandeConfig2: {},
                purchases: [],
                inspectiondata: {
                    'explain': '',
                    files: []
                },
                loading: true,
                importWordDisplay: false,
                importFinish: false,
                wordFilePatch: '',
                nodes: [],
                items: [{
                    label: '采购操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '复制采购',
                            icon: 'pi pi-fw pi-user',
                            command: () => {
                                this.copyPurchase();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '新建采购',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                window.location.href =
                                    '/#/crmpurchase/purchasedraftbox?createpurchase=1';
                            }
                        },
                        {
                            separator: true
                        }, {
                            label: '修改采购数量申请',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createPurchaseEdit();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '采购单打印',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.exportPurchase();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                childNewDataConfig: {
                    products: false,
                    files: false,
                    contractterms: false,
                },
                childDelDataConfig: {
                    products: false,
                    files: false,
                    deliverys: false,
                },
                childTableFooter: {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                },
                mainelementCss: {
                    deliverytimeexplain: 'field col-12 md:col-4',
                    paymenttype: 'field col-12 md:col-4',
                    shippingdate: 'field col-12 md:col-4'
                },
                dropOtherConfig: {
                    currency: 'exchangerate'
                },
                usermoduleauth: [],
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.skuval = '';
                this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.usermoduleauth = [];
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.pageJsonConfig1 = data.page;
                    this.pageJsonConfig2 = data.page2;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig3'
                    ];
                    this.childExpandeConfig1 = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig3'
                    ];
                    this.childExpandeConfig2 = data.page2['region_3']['left']['element'][0][
                        'childExpandeConfig3'
                    ];
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.loadFlowInfo();
                    this.loadUserAuth();
                    this.loadMenuTree();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    console.log(FilterOperator);
                    console.log(JSON.stringify(this.listcolumns));
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            getNewId() {
                var id = -1 * this.newId;
                this.newId = this.newId + 1;
                return id;
            },
            initFilters1() {
                this.skuval = '';
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '692',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;
                        for (var i = 0; i < this.usermoduleauth.length; i++) {
                            if (this.usermoduleauth[i].appid == 'CrmPurchaseAdmin' && this.usermoduleauth[i]
                                .own) {
                                this.readOnly = false;
                            }
                        }
                    }
                });
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('692', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '采购列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.currentPage = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'approvedbox',
                        value: '',
                        operation: '='
                    }, {
                        name: 'sku',
                        value: this.skuval,
                        operation: ''
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'purchaseno,factoryname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{purchaselist(where:" + JSON.stringify(listwhere) +
                    "){id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.purchases = jsonData.data.purchaselist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openPurchase(v_data, v_isCopy) {
                this.childNewDataConfig = {
                    products: false,
                    files: false,
                    contractterms: false,
                };
                this.childDelDataConfig = {
                    products: false,
                    files: false,
                    deliverys: false,
                };
                this.editHistoryButTxt = '显示修改痕迹';
                if (v_isCopy) {
                    this.readOnly = false;
                    this.currentState = '流程信息';
                    this.recordsubject = '复制采购单' + v_data.purchaseno;
                } else {
                    this.readOnly = true;
                    this.currentState = '流程信息';
                    this.recordsubject = '采购单' + v_data.purchaseno;
                }
                this.childTableFooter = {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.currentRow = {
                    isCopy: v_isCopy,
                    bindpage: 'purchase',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'Purchase',
                    boxid: v_data.boxid,
                    stateid: v_data.stateid,
                    flowid: v_data.flowid,
                };
                this.pageJsonConfig = this.pageJsonConfig1;
                this.childExpandeConfig = this.childExpandeConfig1;
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            createPurchaseEdit() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreatePurchase')) {
                    MessageTip.warnmsg('请联系管理员开通新建采购权限');
                    return;
                }
                if (!this.selectedRow) {
                    MessageTip.warnmsg('请先选择要修改的采购单');
                    return;
                }
                this.childNewDataConfig = {
                    products: false,
                    deliverys: false,
                };
                this.childDelDataConfig = {
                    products: true,
                    deliverys: true,
                };
                this.recordsubject = '修改采购数量申请';
                this.childTableFooter = {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                    }
                };
                this.currentRow = {
                    bindpage: 'purchaseedit',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'PurchaseEdit',
                    boxid: '',
                    stateid: this.startStateId2,
                    flowid: this.flowId2,
                    associationid: this.selectedRow['id']
                };
                this.childExpandeConfig = this.childExpandeConfig2;
                this.pageJsonConfig = this.pageJsonConfig2;
                this.readOnly = false;
                this.displayMaximizable = true;
            },
            copyPurchase() {
                if (!this.selectedRow) {
                    MessageTip.warnmsg('请选择要复制的采购单');
                    return;
                }
                this.selectedRow['workflowid'] = '-1';
                this.selectedRow['boxid'] = '-1';
                this.selectedRow['stateid'] = '-1';
                window.location.href = '/#/crmpurchase/purchasedraftbox?copyid=' + this.selectedRow['id'] + '&copyno=' +
                    this.selectedRow['purchaseno'];
            },
            freshList() {
                this.butLoading = false;
                this.loadData(this.currentPage);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                v_data['approvestatus'] = '0';
                v_data['appresult'] = {};
                v_data['shyj'] = {};
                if (v_data['products']) {
                    for (var i = 0; i < v_data['products'].length; i++) {
                        v_data['products'][i]['inspection'] = '0';
                        v_data['products'][i]['inspectionexplain'] = '';
                        v_data['products'][i]['inspectionfile'] = [];
                    }
                }
                return v_data;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
                this.editHistoryButTxt = '显示修改痕迹';
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            formatData(v_value) {
                if (v_value == '1') {
                    return '是';
                } else {
                    return '否';
                }
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj) {
                console.log('cellEditCompleteMethod..');
                console.log(data);
                if (tablename == 'products' && field == 'quantity' || field == 'unitprice') {
                    var quantitynum = Number(rowdata['quantity']);
                    var unitpricenum = Number(rowdata['unitprice']);
                    if (!isNaN(quantitynum) && !isNaN(unitpricenum)) {
                        rowdata['allprice'] = quantitynum * unitpricenum;
                    }
                    var summary = 0;
                    for (var i = 0; i < data.length; i++) {
                        var tempnum = Number(data[i]['allprice']);
                        if (!isNaN(tempnum)) {
                            summary = summary + tempnum;
                        }
                    }
                    console.log('cellEditCompleteMethod..' + summary);
                    childTableFooterObj['products']['allprice'] = summary;
                }
            },
            initDetail(v_editorMenu) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'id',
                        value: 'PurchaseTerm',
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{generaldictsetlist(where:" + JSON.stringify(listwhere) +
                    "){name categories data {rowid name colname colvalue coltype}}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        var retData = jsonData.data.generaldictsetlist;
                        for (var i = 0; i < retData.length; i++) {
                            var rowObj = {
                                name: retData[i]['name'],
                                categories: retData[i]['categories'],
                                rows: [],
                                cols: [],
                            };
                            for (var j = 0; j < retData[i]['data'].length; j++) {
                                var isAdd = true;
                                for (var k1 = 0; k1 < rowObj.cols.length; k1++) {
                                    if (rowObj.cols[k1]['name'] == retData[i]['data'][j]['colname']) {
                                        isAdd = false;
                                        break;
                                    }
                                }
                                if (isAdd) {
                                    rowObj.cols.push({
                                        label: retData[i]['data'][j]['name'],
                                        name: retData[i]['data'][j]['colname'],
                                        type: retData[i]['data'][j]['coltype']
                                    });
                                }
                            }
                            for (var j1 = 0; j1 < retData[i]['data'].length; j1++) {
                                var rowAdd = true;
                                for (var k2 = 0; k2 < rowObj.rows.length; k2++) {
                                    if (retData[i]['data'][j1]['rowid'] == rowObj.rows[k2]['id']) {
                                        rowAdd = false;
                                        break;
                                    }
                                }
                                if (rowAdd) {
                                    var rowData = {
                                        id: retData[i]['data'][j1]['rowid']
                                    };
                                    for (var k3 = 0; k3 < retData[i]['data'].length; k3++) {
                                        if (retData[i]['data'][j1]['rowid'] == retData[i]['data'][k3][
                                                'rowid'
                                            ]) {
                                            rowData[retData[i]['data'][k3]['colname']] = retData[i]['data'][k3][
                                                'colvalue'
                                            ];
                                        }
                                    }
                                    rowObj.rows.push(rowData);
                                }
                            }
                            for (var k = 0; k < rowObj.rows.length; k++) {
                                v_editorMenu.push({
                                    label: rowObj.rows[k]['SName'],
                                    icon: 'pi pi-plus',
                                    colname: 'termcontent',
                                    content: rowObj.rows[k]['AdditionalInfo'],
                                    command: ($event) => {
                                        this.$refs.universalpage.setEditorValue($event);
                                    }
                                });
                            }
                        }
                    }
                });
            },
            expandertoggle(event, data) {
                console.log(data);
                this.expandedata = data;
                this.$refs.op.toggle(event);
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                if (this.currentRow['id'] == '-1' && this.currentRow['bindpage'] == 'purchaseedit') {
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{purchase(id:\"" + this.currentRow['associationid'] +
                        "\"){id purchaseno factoryid linkmanid signingdate currency currencyname exchangerate ownerid ourcompanyid factoryname linkmanname ourcompany owner products{id productid mpn sku factorysku productname color colorname quantity producttype shipquantity ownedstoreid} deliverys{id content ordertime deliverytime containersize containersizename products{id productid parentid tblpurchaseid productname mpn sku quantity factorysku}} }}"
                    ).then(res => {
                        this.loading = false;
                        if (res.errcode == "0") {
                            var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                            var purchaseobj = jsonData.data.purchase;
                            for (var proname in purchaseobj) {
                                if (proname != 'id') {
                                    if (proname == 'products') {
                                        for (var i = 0; i < v_childelements.length; i++) {
                                            if (v_childelements[i]['name'] == 'products') {
                                                v_childelements[i]['rows'] = [];
                                                for (var i2 = 0; i2 < purchaseobj['products'].length; i2++) {
                                                    purchaseobj['products'][i2]['associationid'] = purchaseobj[
                                                        'products'][i2]['id'];
                                                    purchaseobj['products'][i2]['id'] = this.$refs.universalpage
                                                        .getNewId();
                                                    purchaseobj['products'][i2]['parentid'] = '';
                                                    v_childelements[i]['rows'].push(purchaseobj['products'][
                                                        i2
                                                    ]);
                                                }
                                                break;
                                            }
                                        }
                                    }
                                    if (proname == 'deliverys') {
                                        for (var i3 = 0; i3 < v_childelements.length; i3++) {
                                            if (v_childelements[i3]['name'] == 'deliverys') {
                                                v_childelements[i3]['rows'] = [];
                                                for (var i4 = 0; i4 < purchaseobj['deliverys'].length; i4++) {
                                                    purchaseobj['deliverys'][i4]['associationid'] = purchaseobj[
                                                        'deliverys'][i4]['id'];
                                                    purchaseobj['deliverys'][i4]['id'] = this.$refs
                                                        .universalpage.getNewId();
                                                    purchaseobj['deliverys'][i4]['parentid'] = '';
                                                    var deliverysobj = purchaseobj['deliverys'][i4]['products'];
                                                    for (var i5 = 0; i5 < deliverysobj.length; i5++) {
                                                        deliverysobj[i5]['associationid'] = deliverysobj[i5][
                                                            'id'
                                                        ];
                                                        deliverysobj[i5]['id'] = this.$refs.universalpage
                                                            .getNewId();
                                                        deliverysobj[i5]['parentid'] = '';
                                                        deliverysobj[i5]['tblpurchaseid'] = '';
                                                    }
                                                    v_childelements[i3]['rows'].push(purchaseobj[
                                                        'deliverys'][i4]);
                                                }
                                                break;
                                            }
                                        }
                                    }
                                    if (proname != 'products' && proname != 'deliverys') {
                                        for (var k1 = 0; k1 < v_mainelements.length; k1++) {
                                            if (v_mainelements[k1]['name'] == proname) {
                                                v_mainelements[k1]['val'] = purchaseobj[proname];
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                            v_originalData['purchaseedit']['associationid'] = this.currentRow['associationid'];
                            v_noshowelements['associationid'] = this.currentRow['associationid'];
                            v_originalData['purchaseedit']['factoryid'] = purchaseobj['factoryid'];
                            v_noshowelements['factoryid'] = purchaseobj['factoryid'];
                            v_originalData['purchaseedit']['linkmanid'] = purchaseobj['linkmanid'];
                            v_noshowelements['linkmanid'] = purchaseobj['linkmanid'];
                            v_originalData['purchaseedit']['currency'] = purchaseobj['currency'];
                            v_noshowelements['currency'] = purchaseobj['currency'];
                            v_originalData['purchaseedit']['ownerid'] = purchaseobj['ownerid'];
                            v_noshowelements['ownerid'] = purchaseobj['ownerid'];
                            v_originalData['purchaseedit']['ourcompanyid'] = purchaseobj['ourcompanyid'];
                            v_noshowelements['ourcompanyid'] = purchaseobj['ourcompanyid'];

                            setTimeout(() => {
                                this.$refs.universalpage.setAllExpandedRowsData('deliverys');
                            }, 20);
                        }
                    });
                } else {
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'currency') {
                            this.currencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                                v_childExpandeConfig);
                        }
                    }
                    setTimeout(() => {
                        this.$refs.universalpage.setAllExpandedRowsData('deliverys');
                    }, 20);
                }
            },
            currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var loopcnt = 0;
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '单价(' + ename + ')';
                            loopcnt++;
                        }
                        if (v_childelements[i].columns[i2].field == 'allprice') {
                            v_childelements[i].columns[i2].header = '总价(' + ename + ')';
                            loopcnt++;
                        }
                        if (loopcnt == 2) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                for (var i4 = 0; i4 < v_childExpandeConfig.products.config2[0].columns.length; i4++) {
                    if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'unitprice') {
                        v_childExpandeConfig.products.config2[0].columns[i4].header = '单价(' + ename + ')';
                    }
                    if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'allprice') {
                        v_childExpandeConfig.products.config2[0].columns[i4].header = '总价(' + ename + ')';
                    }
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            exportPurchase3() {
                if (!this.selectedRow) {
                    MessageTip.warnmsg('请选择要打印的采购单');
                    return;
                }
                if (this.selectedRow['approvestatus'] != '1') {
                    MessageTip.warnmsg('采购单未审核通过不能打印');
                    return;
                }
                var exportInput = {
                    Id: this.selectedRow['id'],
                };
                this.wordFilePatch = '';
                this.importFinish = false;
                this.importWordDisplay = true;
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/SYPurchaseExport?appkey=' + sessionStorage.getItem("appkey"), exportInput)
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('exportPurchase3:');
                                console.log(retObj);
                                this.wordFilePatch = retObj.filepatch;
                                this.importFinish = true;
                            } else {
                                this.importWordDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            exportPurchase2() {
                var exportInput = {
                    templateId: '330637473996869',
                    appKey: sessionStorage.getItem("appkey"),
                    conditions: [{
                        schemaName: 'purchase',
                        conditionValue: [{
                            name: 'id',
                            value: this.selectedRow['id'],
                            operation: '='
                        }, {
                            name: 'export',
                            value: '',
                            operation: '='
                        }],
                    }],
                };
                this.wordFilePatch = '';
                this.importFinish = false;
                this.importWordDisplay = true;
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/WordTemplateExport?appkey=' + sessionStorage.getItem("appkey"), exportInput)
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('exportPurchase2:');
                                console.log(retObj);
                                this.wordFilePatch = retObj.filepatch;
                                this.importFinish = true;
                                console.log(this.wordFilePatch);
                            } else {
                                this.importExcelDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            exportPurchase() {
                if (sessionStorage.getItem("corpid") == "230001") {
                    this.exportPurchase3();
                    return;
                }
                if (!this.selectedRow) {
                    MessageTip.warnmsg('请选择要打印的采购单');
                    return;
                }
                if (this.selectedRow['approvestatus'] != '1') {
                    MessageTip.warnmsg('采购单未审核通过不能打印');
                    return;
                }
                var exportInput = {
                    id: this.selectedRow['id'],
                };
                this.wordFilePatch = '';
                this.importFinish = false;
                this.importWordDisplay = true;
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/PurchaseContract?appkey=' + sessionStorage.getItem("appkey"), exportInput)
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('exportPurchase:');
                                console.log(retObj);
                                this.wordFilePatch = retObj.filepatch;
                                this.importFinish = true;
                            } else {
                                this.importWordDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            getWordFilePatch() {
                location.href = '/calf/plat/file/' + this.wordFilePatch + '?appkey=' + sessionStorage.getItem("appkey");
                return;
            },
            showEditHistory() {
                if (this.editHistoryButTxt == '隐藏修改痕迹') {
                    this.$refs.universalpage.clearEditHistory();
                    this.editHistoryButTxt = '显示修改痕迹';
                    return;
                }
                this.$refs.universalpage.showEditHistory(this.seteditHistoryButTxt);
            },
            seteditHistoryButTxt() {
                this.editHistoryButTxt = '隐藏修改痕迹';
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'PurchaseEdit',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId2 = retObj.FlowId;
                        this.startStateId2 = retObj.CurrentStateId;
                    }
                });
            },
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
            // Image,
        }
    }
</script>
<style lang="scss" scoped>
    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }
</style>